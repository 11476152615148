import styled from 'styled-components';

export const StyledButton = styled.button<{
  widthPercent?: number | undefined;
  $size?: 'default';
  $style?: 'white' | 'outlined' | 'black';
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ widthPercent }) => (widthPercent ? `${widthPercent}%` : '100%')};
  ${({ theme, $size }) => theme.buttonSize[$size || 'default']};
  ${({ theme, $style }) => theme.buttonColor[$style || 'white'].default};

  &:hover,
  &:active {
    ${({ theme, $style }) => theme.buttonColor[$style || 'white'].pressed};
  }

  ${({ theme, $style, disabled }) =>
    disabled && theme.buttonColor[$style || 'white'].disabled};
`;
