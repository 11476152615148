import { useQuery, UseQueryResult } from '@tanstack/react-query';
import usersApi from '@src/apis/usersApi';
import { RED_DOT } from '@src/queryKeys';
import useAccessToken from '@hooks/useAccessToken';

const useGetRedDot = (): UseQueryResult<boolean> => {
  const [accessToken] = useAccessToken();
  return useQuery({
    queryKey: [RED_DOT],
    queryFn: async () => {
      const res = await usersApi.getPushNotView();
      return res.data;
    },
    ...{
      refetchInterval: (data) => {
        if (!accessToken) return false;
        return data ? false : 5000;
      },
    },
  });
};

export default useGetRedDot;
