import { GetStaticProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import MainContent from '@features/MainContent';
import withPageLayout from '@src/hoc/withPageLayout';
import PushAndSettingIconButtons from '@components/layout/PushAndSettingIconButtons';

const MainComponent = withPageLayout(MainContent);

const Main: NextPage = () => {
  return (
    <MainComponent
      $style="transparent"
      titleAlign="left"
      headerRightButton={<PushAndSettingIconButtons />}
    />
  );
};

export const getStaticProps: GetStaticProps = async ({ locale = 'ko' }) => ({
  props: {
    ...(await serverSideTranslations(locale)),
  },
});

export default Main;
